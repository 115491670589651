<template>
  <div>
    <b-card title="Welcome to Ohlive Dashboard">
      <!-- <b-card-text></b-card-text> -->
    </b-card>
  </div>
</template>

<script>
import { BCard, BCardText, BLink } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardText,
    BLink,
  },
}
</script>

<style>

</style>